<template>
  <div class="booker-awards-container" v-if="category">
    <CategoryTitle :category="category" />

    <span class="d-block mt-5">{{ $t("awards.completed.email") }}</span>

    <span
      v-if="store.addressId == 159"
      class="d-block mt-5"
      v-html="$t('awards.completed.home')"
    ></span>
    <span
      v-else-if="store.addressId == 160"
      class="d-block mt-5"
      v-html="$t('awards.completed.drive')"
    >
    </span>
    <span
      v-else
      class="d-block mt-5"
      v-html="
        $t('awards.completed.generic', {
          addressName: store.addressName,
          address1: store.address1,
          addressNumber: store.addressNumber,
          postalCode: store.postalCode,
          city: store.city
        })
      "
    ></span>
  </div>
</template>
<style lang="scss"></style>
<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";

import categoryMixins from "~/mixins/category";

import { mapGetters } from "vuex";

export default {
  name: "BookingCompleted",
  components: {
    CategoryTitle
  },
  mixins: [categoryMixins],
  props: { store: { type: Object } },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      user: "cart/getUser"
    })
  },
  methods: {},
  mounted() {
    console.log("store completed", this.store);
  }
};
</script>
