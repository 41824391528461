var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.category)?_c('div',{staticClass:"booker-awards-container"},[_c('CategoryTitle',{attrs:{"category":_vm.category}}),_c('span',{staticClass:"d-block mt-5"},[_vm._v(_vm._s(_vm.$t("awards.completed.email")))]),(_vm.store.addressId == 159)?_c('span',{staticClass:"d-block mt-5",domProps:{"innerHTML":_vm._s(_vm.$t('awards.completed.home'))}}):(_vm.store.addressId == 160)?_c('span',{staticClass:"d-block mt-5",domProps:{"innerHTML":_vm._s(_vm.$t('awards.completed.drive'))}}):_c('span',{staticClass:"d-block mt-5",domProps:{"innerHTML":_vm._s(
      _vm.$t('awards.completed.generic', {
        addressName: _vm.store.addressName,
        address1: _vm.store.address1,
        addressNumber: _vm.store.addressNumber,
        postalCode: _vm.store.postalCode,
        city: _vm.store.city
      })
    )}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }